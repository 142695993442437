<template>
  <div class="text-center">
    <h2 class="h2 my-5">產品類別</h2>
    <div class="table-responsive-xl">
      <table class="table table-hover mx-auto" style="width: 600px">
        <thead class="thead-dark">
          <tr class="text-left">
            <th>名稱</th>
            <th width="150">路徑</th>
            <th colspan="2" class="text-center">操作</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="item in categories" :key="item.id">
            <tr class="text-left">
              <td class="align-middle">
                <input type="text" v-model="item.name" class="form-control" />
              </td>
              <td class="align-middle" width="150">
                <input type="text" class="form-control" v-model="item.path" />
              </td>
              <td class="align-middle" width="100">
                <button
                  type="button"
                  class="btn btn-outline-danger"
                  @click="openModal('edit', item)"
                >
                  修改
                </button>
              </td>
              <td class="align-middle" width="100">
                <button
                  type="button"
                  class="btn btn-outline-danger"
                  @click="openModal('remove', item)"
                >
                  刪除
                </button>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>

    <h2 class="h2 mt-5">新增類別</h2>
    <div class="table-responsive-xl">
      <table class="table table-hover mt-5 mx-auto" style="width: 600px">
        <thead class="thead-dark">
          <tr>
            <th>類別名稱</th>
            <th>類別路徑</th>
            <th width="100"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <input
                type="text"
                class="form-control"
                placeholder="請輸入類別名稱"
                v-model="name"
              />
            </td>
            <td>
              <input
                type="text"
                class="form-control"
                placeholder="請輸入類別路徑(英文)"
                v-model="path"
              />
            </td>
            <td width="100">
              <button
                type="button"
                class="btn btn-danger"
                @click="addCategory"
                :disabled="name.trim() === '' || path.trim() === ''"
              >
                新增
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <Modal @submit="submit">
    <!-- title -->
    <template v-slot:title>
      <div v-if="modalType === 'edit'">編輯</div>
      <div v-if="modalType === 'remove'">刪除</div>
    </template>
    <!-- body -->
    <template v-slot:body>
      <div v-if="modalType === 'edit'">
        是否要修改成 <br />
        名稱 {{ temp.name }} <br />
        路徑 {{ temp.path }}
      </div>
      <div v-if="modalType === 'remove'">是否要將 {{ temp.name }} 刪除</div>
    </template>
  </Modal>
</template>

<script>
import $ from "jquery";
import { ref, onMounted, inject } from "vue";
import {
  fetchCategories,
  create,
  edit,
  remove,
} from "connection/admin/categoryDB";
import Modal from "components/back/Modal";
export default {
  name: "category",
  components: {
    Modal,
  },
  setup() {
    //初始化
    const mitt = inject("mitt");
    const categories = ref([]);
    const getAllCategories = async () => {
      try {
        mitt.emit("loading", true);
        categories.value = await fetchCategories();
        mitt.emit("loading", false);
      } catch (error) {
        mitt.emit("alert", { state: "danger", message: error.message });
      }
    };
    onMounted(() => {
      getAllCategories();
    });
    //新增
    const name = ref("");
    const path = ref("");

    const addCategory = async () => {
      try {
        await create(name.value, path.value);
        getAllCategories();
        name.value = "";
        path.value = "";
        mitt.emit("alert", { state: "success", message: "新增類別成功" });
      } catch (error) {
        mitt.emit("alert", { state: "danger", message: error.message });
      }
    };
    //修改
    const editCategory = async (item) => {
      try {
        await edit(item);
        getAllCategories();
        mitt.emit("alert", { state: "success", message: "類別已修改" });
      } catch (error) {
        mitt.emit("alert", { state: "danger", message: error.message });
      }
    };
    //刪除
    const removeCategory = async (id) => {
      try {
        await remove(id);
        getAllCategories();
        mitt.emit("alert", { state: "success", message: "類別已刪除" });
      } catch (error) {
        mitt.emit("alert", { state: "danger", message: error.message });
      }
    };

    const temp = ref({});
    const modalType = ref("");

    const openModal = (type, item) => {
      modalType.value = type;
      Object.assign(temp.value, item);
    };
    const submit = () => {
      if (modalType.value === "edit") {
        editCategory(temp.value);
      } else if (modalType.value === "remove") {
        removeCategory(temp.value.id);
      }
      $("#Modal").modal("hide");
    };
    return {
      categories,
      name,
      path,
      temp,
      addCategory,
      editCategory,
      removeCategory,
      modalType,
      openModal,
      submit,
    };
  },
};
</script>
